.flag {
    position: fixed;
    top : 30%;
    left: 50%;
    transform: translateX(-50%);
    background-color: ghostwhite;
    color: #282c34;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: x-large;
    font-weight: bold;
    opacity: 0;
    transition: top 1s ease, opacity 0.5s ease;
    z-index: 9999;
    border: 3px solid #1a1a1a;  /* Outer green line */
    box-sizing: border-box;    /* Ensure border does not affect overall size */
}

.flag.visible {
    top: 40%;
    opacity: 1;
    visibility: visible;
}

.flag.move-down {
    top: 50%
}