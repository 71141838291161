.App {
  text-align: center;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  display: inline-block;  /* Makes the <a> element only as wide as its content (the <img>) */
}

.ranking {
  cursor: pointer;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 0.9em;
  overflow: hidden;
  width: 40px;
  height: 40px;
  object-fit: cover;
  background-color: #e8e8e8;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Container for input box */
.input-container {
  text-align: center; /* Centers input box horizontally */
  width: 25em;
  padding-left: 2em;
  display: flex;
  gap: 10px;
}

.create-button {
  color: #03ffa3;
  background-color: black;
  border-radius: 10px;
  padding: 8px;
  width: 250px;
  font-size: 1.6rem;
  font-weight: 600;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
}

.create-button:hover {
  transform: translateY(-1px) translateX(-0.5px); /* Moves the button down */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Simulates a pressed shadow */
}

.create-button:active {
  transform: translateY(1px) translateX(0.5px); /* Moves the button down */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Simulates a pressed shadow */
}

.content {
  height: calc(100vh - 3rem - 58px - 56px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; /* Ensure wrapping on smaller screens */
}

.footer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 3rem;
  background-color: #333333;
}

.discord {
  display: inline-block;  /* Makes the <a> element only as wide as its content (the <img>) */
  position: absolute;
  right: 20px;
  bottom: 10px;
}

.discordImg {
  width: 120px;
  height: auto;
  float: right;
}

.toggleContainer {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.right-content {
  flex-wrap: wrap;
}

.menu-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

/* Default for larger screens (PC) */
@media (min-width: 768px) {
  .content {
    flex-direction: row; /* Horizontal layout for PC */
  }
  .content iframe {
    width: 420px;
    height: 315px;
  }
  .gamble {
    min-width: 130px;
  }
}

@media (max-width: 1060px) {
  .footer {
    display: none;
  }
}

/* For mobile and smaller screens */
@media (max-width: 767px) {
  .right-content {
    width: 95%;
    height: 70%;
  }
  .header {
    padding-inline: 0.5em; /*left/right*/
  }
  .ranking {
    min-width: 40px;
    margin-left: 5px;
  }
  .input-container {
    width: 15em;
    padding-left: 1em;
  }
  .create-button {
    font-size: 1.3rem;
    max-width: 100px;
  }
  .content {
    display: flex;
    flex-direction: row; /* Vertical layout for mobile */
    gap: 5px
  }
  .gamble {
    display: none;
  }
  .content iframe {
    width: 80%;
    height: 25%;
    margin-bottom: 25px;
  }
  .name {
    display: none;
  }
  .header_right {
    padding-left: 10px;
  }
  .footer {
    display: none;
  }
}


