.content-container-landing {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 150px;
    overflow: scroll;
    padding: 20px 0 20px 0;
}

.menu {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@media (max-width: 767px) {
    .content-container-landing {
        justify-content: start;
        flex-direction: column;
        gap: 40px;
    }
}
