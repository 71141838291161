.App {
  text-align: center;
  background-color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Wheel {
  display: flex;
  justify-content: center;
  padding: 10px 10px 10px 10px;
  width: 100%;
  height: 100vh;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Container for input box */
.input-container {
  text-align: center; /* Centers input box horizontally */
  width: 25em;
  padding-left: 2em;
  display: flex;
  gap: 10px;
}

.create-button {
  color: #03ffa3;
  background-color: black;
  border-radius: 10px;
  padding: 8px;
  width: 250px;
  font-size: 1.6rem;
  font-weight: 600;
}

.content-container {
  height: calc(100vh - 3rem - 64px - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  margin-bottom: 50px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 3rem;
  background-color: #333333;
}

@media (max-width: 767px) {
  .footer {
    display: none;
  }
}

