.content-box {
    padding: 16px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    margin: 16px 0;
    width: 250px;
}

.content-box h2 {
    font-size: 1.5rem;
    margin-bottom: 8px;
    color: #333;
    font-weight: bold;
}

.content-box p {
    font-size: 1rem;
    color: #555;
}

.content-box .tag {
    display: inline-block;
    color: #777;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.875rem;
    margin-top: 12px;
}

@media (max-width: 767px) {
    .content-box {
        width: 300px;
    }
}
