/* TipComponent.css */

.tip-container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 60%;
    height: 40px; /* Adjust based on your design */
    overflow: hidden;
    font-size: large;
    color: white;
}

.tip {
    position: absolute;
    animation: slideInOut 10s infinite;
    padding: 3px 5px 3px 5px;
    align-items: center;
}

@keyframes slideInOut {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    10% {
        transform: translateY(0);
        opacity: 1;
    }
    90% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.tip-blind {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    background-color: #f0f0f0;
    font-size: 18px;
    text-align: center;
    color: #555;
}

@media (max-width: 1057px) {
    .tip-container {
        display: none;
    }
}