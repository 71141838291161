.button {
  color: #03ffa3;
  background-color: black;
  border-radius: 10px;
  padding: 8px;
  font-size: 2rem;
  min-width: 100px;
  font-weight: 600;
  will-change: transform;
}

.button:hover,
.create-button:hover {
  color: white;
  background-color: #04c289;
}

.button[disabled] {
  background-color: #666666;
  color: #999999; /* 필요시 색상 변경 */
}
