.tableContainer {
    overflow-y: auto;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table {
    width: 100%; /* Ensures the table stretches to fill the screen */
    border-collapse: collapse;
    background-color: #ffffff;
    padding: 5px;
}

.th {
    position: sticky;
    top: -1px;
    background-color: #f5f5f5;
    color: #333;
    font-weight: bold;
    text-align: center;
    padding: 12px;
    border-bottom: 2px solid #ddd;
}

.td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
}

.tr:last-child .td {
    border-bottom: none;
}

.tr:nth-child(even) {
    background-color: #f9f9f9;
}

.tr:hover {
    background-color: #f0f0f0;
}

th:nth-child(2), td:nth-child(2) {
    text-align: left;
    min-width: 200px;
    overflow-wrap: anywhere;
    word-break: normal;
}

.generalSelected {
    background-color: orange; /* Change to any color you'd like */
}

.highlighterSelected {
    background-color: lightskyblue; /* Change to any color you'd like */
}

/* Default for larger screens (PC) */
@media (min-width: 768px) {
    .tableContainer {
        max-width: 600px;
        max-height: 400px;
    }
}

/* For mobile and smaller screens */
@media (max-width: 767px) {
    .tableContainer {
        font-size: 13px;
        width: 100%;
        max-height: 100%;
    }
    .th {
        padding: 10px 0 10px 10px;
    }
    .td {
        padding: 10px 0 10px 10px;
    }
}

@media (max-width: 380px) {
    .th {
        padding: 7px 0 7px 7px;
    }
    .td {
        padding: 7px 0 7px 7px;
    }
}