.canvasContainer {
  position: relative;
  width: 500px;
  height: 500px;
  display: flex;
  justify-items: center;
  align-items: center;
  border-radius: 50%;
  border: 3px solid black;
}

.wheel {
  width: 100%;
  height: 100%;
  will-change: transform;
}

.pin {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
}

.selectedOption {
  color: lightcoral;
  font-size: 2rem;
  font-weight: 600;
  width: 500px;
  height: 5%;
  margin-bottom: 2%;
  overflow: hidden;
  white-space: nowrap;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  width: 100%;
  justify-content: center;
  gap: 50px;
}

.wheelContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
